import { graphql } from "gatsby";
import { countBy } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";

import Layout from "../../components/Layout";
import Seo from "../../components/seo";
import { IS_LIKED, ORDERED_LIKE } from "../../helpers/constants";
import { getClientIp } from "../../helpers/getClientIp";
import {
  InspirationContainerContext,
  InspirationPageContext,
} from "../../helpers/pagesContext";
import { INSPIRATIONS } from "../../helpers/route";
import InspirationScene from "../../scenes/Inspiration/Inspiration";

const INSPIRATION_ITEM_INDEX = {
  1: "post",
  2: ["podcast", "podcast external"],
  3: "video",
  4: "image",
  5: "quote",
};

export default function Inspiration(props) {
  const [ip, setIp] = useState(null);

  useEffect(() => {
    if (!ip)
      getClientIp().then(function (result) {
        setIp(result);
      });
  }, []);

  const blogEdges = [
    ...props.data.allContentfulInspirationPost.edges,
    ...props.data.allContentfulInspirationQuote.edges,
    ...props.data.allContentfulInspirationPodcast.edges,
    ...props.data.allContentfulInspirationVideo.edges,
    ...props.data.allContentfulInspirationPodcastExternal.edges,
    ...props.data.allContentfulInspirationImage.edges,
  ];

  const [sorted, setSorted] = useState(true);
  const [result, setResult] = useState(
    blogEdges.filter((element) => element.node.title !== "dummy"),
  );

  if (sorted) {
    result.sort(function (a, b) {
      a = new moment(a.node.creation);
      b = new moment(b.node.creation);
      return a > b ? -1 : 1;
    });
  }

  const filter = async (value) => {
    setSorted(true);

    if (value === IS_LIKED) return isLiked();
    if (value === ORDERED_LIKE) return setLikeOrder();

    if (value) {
      const blogEdgesFilter = [];

      const blogEdgesSkipDummyElement = blogEdges.filter(
        (element) => element.node.title !== "dummy",
      );

      for (const item of value.inspirations) {
        blogEdgesFilter.push(
          ...blogEdgesSkipDummyElement.filter((element) =>
            INSPIRATION_ITEM_INDEX[item].includes(element.node.type),
          ),
        );
      }

      return setResult(blogEdgesFilter);
    } else {
      return setResult(
        blogEdges.filter((element) => element.node.title !== "dummy"),
      );
    }
  };

  const setLikeOrder = async () => {
    setSorted(false);
    const json = await getLikedArticle();
    const filtered = json.records.filter((record) => record.fields);

    const id = filtered.map((elem) => {
      return elem.fields.url;
    });
    const counted = countBy(id);

    const countedBlogEdges = blogEdges.map((elem) => {
      elem.node["like"] = counted[elem.node.url] ? counted[elem.node.url] : 0;
      return elem;
    });

    countedBlogEdges.sort(function (a, b) {
      return a.node.like > b.node.like ? -1 : 1;
    });

    setResult(
      countedBlogEdges.filter((element) => element.node.title !== "dummy"),
    );
  };

  const isLiked = async () => {
    const json = await getLikedArticle();
    const filtered = json.records.filter((record) => record.fields.ip === ip);

    const id = filtered.map((elem) => {
      return elem.fields.url;
    });

    setResult(blogEdges.filter((element) => id.includes(element.node.url)));
  };

  const getLikedArticle = async () => {
    const res = await fetch(
      `https://api.airtable.com/v0/${process.env.GATSBY_AIRTABLE_BASE}/Projects`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${process.env.GATSBY_AIRTABLE_TOKEN}`,
        },
      },
    );
    return await res.json();
  };

  return (
    <Layout currentRoute={INSPIRATIONS} data={props.data.contentfulMenuLabel}>
      <Seo
        title="Inspirations"
        description="Articles, Podcasts, Vidéos, Photos, Citations, Medias... Le blog multi-formats qui regroupe les inspirations, réflexions autours du test qui anime Zenity et ses étoiles experts en testing digital"
      />
      <InspirationPageContext.Provider value={{ result, filter }}>
        <InspirationContainerContext.Provider
          value={props.data.contentfulInspirationContainer}
        >
          <InspirationScene />
        </InspirationContainerContext.Provider>
      </InspirationPageContext.Provider>
    </Layout>
  );
}

export const pageQuery = graphql`
  query MyQuery($locale: String!) {
    contentfulInspirationContainer(node_locale: { eq: $locale }) {
      addBlock
      cancel
      menuInspiration
      menuName
      menuTitle
      node_locale
      nothing
      quality
      shortcut
      title
      search
    }
    allContentfulInspirationPost(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          url
          id
          title
          subtitle
          type
          creation(formatString: "YYYY-MM-DD HH:mm:ss")
          thumbnail {
            gatsbyImageData(layout: FIXED)
          }
          content {
            raw
          }
          author
          time
        }
      }
    }
    allContentfulInspirationPodcast(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          url
          id
          title
          author
          type
          thumbnail {
            gatsbyImageData(layout: FIXED)
          }
          audio {
            file {
              url
            }
          }
          content {
            raw
          }
          creation(formatString: "YYYY-MM-DD HH:mm:ss")
          time
        }
      }
    }
    allContentfulInspirationQuote(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          url
          id
          author
          title
          type
          content {
            raw
            references {
              ... on ContentfulAsset {
                contentful_id
                __typename
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          color {
            black
            blue
            gold
            white
          }
          creation(formatString: "YYYY-MM-DD HH:mm:ss")
        }
      }
    }
    allContentfulInspirationVideo(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          url
          id
          title
          subtitle
          author
          type
          content {
            raw
          }
          thumbnail {
            gatsbyImageData(layout: FIXED)
          }
          video {
            file {
              url
            }
          }
          creation(formatString: "YYYY-MM-DD HH:mm:ss")
          time
        }
      }
    }
    allContentfulInspirationPodcastExternal(
      filter: { node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          idUrl
          author
          source
          content {
            raw
          }
          creation(formatString: "YYYY-MM-DD HH:mm:ss")
          id
          thumbnail {
            gatsbyImageData(layout: FIXED)
          }
          time
          title
          url
          type
        }
      }
    }
    allContentfulInspirationImage(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          url
          id
          thumbnail {
            gatsbyImageData
          }
          author
          creation(formatString: "YYYY-MM-DD HH:mm:ss")
          title
          time
          type
          content {
            raw
          }
        }
      }
    }
    contentfulMenuLabel(node_locale: { eq: $locale }) {
      adventure
      career
      cv
      identity
      innovation
      insideZenity
      inspirations
      node_locale
      recrute
      univers
      contact
      accept
      decline
      cookie
      knowMore
      formation
      ourFormation
      zac
      services
      zenityFactory
    }
  }
`;
