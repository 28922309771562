// extracted by mini-css-extract-plugin
export var activeTab = "SearchMenu-module--activeTab--d8a69";
export var button = "SearchMenu-module--button--ec8a7";
export var buttonCancel = "SearchMenu-module--buttonCancel--50a47";
export var checkboxLabel = "SearchMenu-module--checkboxLabel--6ee27";
export var chevron = "SearchMenu-module--chevron--44a1d";
export var dialBox = "SearchMenu-module--dialBox--65afc";
export var filterBubble = "SearchMenu-module--filterBubble--38297";
export var form = "SearchMenu-module--form--295b0";
export var formGroup = "SearchMenu-module--formGroup--24582";
export var icone = "SearchMenu-module--icone--4528c";
export var paperBox = "SearchMenu-module--paperBox--a7990";
export var slideUp = "SearchMenu-module--slideUp--b3e20";
export var tab = "SearchMenu-module--tab--da1c1";
export var tabs = "SearchMenu-module--tabs--2f742";
export var tabsResp = "SearchMenu-module--tabsResp--0cae4";