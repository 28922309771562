import React from "react";

import * as styles from "./InspirationSearchMobile.module.css";

export const InspirationSearchMobile = ({ edges, item }) => {
  return (
    <div className={styles.container}>
      {edges.map(({ node }, idx) => {
        return (
          <div key={idx} className={styles.item}>
            {item({ node, idx })}
          </div>
        );
      })}
    </div>
  );
};
