// extracted by mini-css-extract-plugin
export var FormGroup = "Inspiration-module--FormGroup--1ce5e";
export var activeTab = "Inspiration-module--activeTab--d1e33";
export var arrow = "Inspiration-module--arrow--8b1c0";
export var arrowEnd = "Inspiration-module--arrowEnd--1846c";
export var chevron = "Inspiration-module--chevron--99223";
export var empty = "Inspiration-module--empty--076e5";
export var emptyPicture = "Inspiration-module--emptyPicture--4b68c";
export var emptyText = "Inspiration-module--emptyText--4be6e";
export var footer = "Inspiration-module--footer--ba781";
export var icone = "Inspiration-module--icone--0756a";
export var line = "Inspiration-module--line--a8a62";
export var paperBox = "Inspiration-module--paperBox--8541f";
export var root = "Inspiration-module--root--ce9cf";
export var searchTitle = "Inspiration-module--searchTitle--0f8c3";
export var slider = "Inspiration-module--slider--4e93a";
export var sliderTitle = "Inspiration-module--sliderTitle--181ce";
export var tab = "Inspiration-module--tab--bf010";
export var tabs = "Inspiration-module--tabs--97070";
export var wrapper = "Inspiration-module--wrapper--783a7";