import useMediaQuery from "@mui/material/useMediaQuery";
import { StaticImage } from "gatsby-plugin-image";
import React, { useContext, useState } from "react";

import { Footer } from "../../components/Footer/Footer";
import {
  InspirationImage,
  InspirationPodcast,
  InspirationPodcastExternal,
  InspirationPost,
  InspirationQuote,
  InspirationVideo,
} from "../../components/Inspiration/Inspiration";
import { getPlainText } from "../../helpers/getPlainText";
import {
  InspirationContainerContext,
  InspirationPageContext,
} from "../../helpers/pagesContext";
import emptyGif from "../../resources/images/article/empty.gif";
import {
  InspirationDesktop,
  InspirationMobile,
  InspirationSearchDesktop,
  InspirationSearchMobile,
} from "./components/Children/InspirationChildren";
import { Hero } from "./components/Hero/Hero";
import SearchMenu from "./components/SearchMenu/SearchMenu";
import * as styles from "./Inspiration.module.css";

const item = ({ node, idx }) => {
  let element;

  switch (node.type) {
    case "post":
      element = (
        <InspirationPost
          {...{
            url: node.url,
            id: node.id,
            idx,
            author: node.author,
            createdAt: node.creation,
            title: node.title,
            subtitle: node.subtitle,
            content: node.content,
            thumbnail: node.thumbnail,
            time: node.time,
          }}
        />
      );
      break;
    case "podcast":
      element = (
        <InspirationPodcast
          {...{
            url: node.url,
            id: node.id,
            idx,
            author: node.author,
            createdAt: node.creation,
            content: node.content,
            title: node.title,
            thumbnail: node.thumbnail,
            source: node.source,
            time: node.time,
          }}
        />
      );
      break;
    case "podcast external":
      element = (
        <InspirationPodcastExternal
          {...{
            idUrl: node.idUrl,
            id: node.id,
            idx,
            author: node.author,
            createdAt: node.creation,
            content: node.content,
            title: node.title,
            thumbnail: node.thumbnail,
            source: node.source,
            time: node.time,
            url: node.url,
          }}
        />
      );
      break;
    case "quote":
      element = (
        <InspirationQuote
          {...{
            id: node.id,
            idx,
            author: node.author,
            content: node.content,
            color: node.color,
            url: node.url,
          }}
        />
      );
      break;
    case "video":
      element = (
        <InspirationVideo
          {...{
            url: node.url,
            id: node.id,
            idx,
            author: node.author,
            createdAt: node.creation,
            title: node.title,
            subtitle: node.subtitle,
            thumbnail: node.thumbnail,
            time: node.time,
          }}
        />
      );
      break;
    case "image":
      element = (
        <InspirationImage
          {...{
            url: node.url,
            id: node.id,
            idx,
            author: node.author,
            createdAt: node.creation,
            title: node.title,
            thumbnail: node.thumbnail,
            time: node.time,
          }}
        />
      );
      break;
    default:
      break;
  }

  return element;
};

export default function Inspiration() {
  const { nothing, menuName, menuTitle, node_locale } = useContext(
    InspirationContainerContext
  );
  const { result: edges } = useContext(InspirationPageContext);
  const mobile = useMediaQuery("(max-width:767.98px)");
  const [isArrowShow, setIsArrowShow] = useState(true);
  const [valueTab, setValueTab] = useState(0);
  const [search, setSearch] = useState({
    items: [],
    value: "",
    searching: false,
  });

  const menu = [
    { name: menuName[0], title: menuTitle[0] },
    { name: menuName[1], title: menuTitle[1] },
    { name: menuName[2], title: menuTitle[2] },
    { name: menuName[3], title: "" },
    {},
  ];

  const handleChange = () => (event) => {
    setSearch({ ...search, value: event.target.value });
  };

  const analysis = () => {
    const temp = [];
    const searching = search.value.trim().toLowerCase();

    if (!searching) {
      setValueTab(0);
      return setSearch({
        ...search,
        items: [],
        value: "",
        searching: false,
      });
    }

    edges.map((value) => {
      let title;
      let content;

      if (value.node.title) {
        title = value.node.title.toLowerCase();
      }

      if (value.node.content) {
        content = getPlainText(value.node.content).toLowerCase();
      }

      if (title?.includes(searching) || content?.includes(searching))
        temp.push(value);
    });

    return setSearch({
      ...search,
      items: temp,
      searching: true,
    });
  };

  const handleClean = () => {
    if (valueTab === 4) {
      setValueTab(0);
    }
    handleCleanSearch();
  };

  const handleCleanSearch = () => {
    setSearch({ ...search, items: [], value: "", searching: false });
  };

  return (
    <>
      <Hero
        search={search}
        handleChange={handleChange}
        handleClean={handleClean}
        analysis={analysis}
        setValueTab={setValueTab}
        setIsArrowShow={setIsArrowShow}
      />
      <div className={styles.root}>
        <SearchMenu
          menu={menu}
          valueTab={valueTab}
          setValueTab={setValueTab}
          cleanSearch={handleCleanSearch}
          searching={search.searching}
          setIsArrowShow={setIsArrowShow}
        />
        {edges.length > 0 && !(search.searching && !search.items.length) && (
          <div className={styles.wrapper}>
            {edges.length > 0 && (
              <>
                <div className={styles.line} />
                {isArrowShow && (
                  <StaticImage
                    className={styles.arrow}
                    src="../../resources/images/actuality-content/arrow.png"
                    alt="vector"
                    width={75}
                  />
                )}
              </>
            )}
            {search.searching ? (
              mobile ? (
                <InspirationSearchMobile
                  edges={search.items}
                  text={search.value}
                  item={item}
                />
              ) : (
                <InspirationSearchDesktop
                  edges={search.items}
                  text={search.value}
                  item={item}
                  setIsArrowShow={setIsArrowShow}
                />
              )
            ) : mobile ? (
              <InspirationMobile
                edges={edges}
                item={item}
                currentMenu={menu[valueTab].name}
              />
            ) : (
              <InspirationDesktop
                edges={edges}
                item={item}
                currentMenu={menu[valueTab].name}
                setIsArrowShow={setIsArrowShow}
              />
            )}
          </div>
        )}
        {((search.searching && !search.items.length) || !edges.length) &&
          search.searching && (
            <div className={styles.empty}>
              <div className={styles.emptyPicture}>
                <img src={emptyGif} alt="empty data" />
              </div>
              <div className={styles.emptyText}>{nothing}</div>
            </div>
          )}
        <div className={styles.footer}>
          <Footer
            background={`linear-gradient(206.5deg, rgb(149 109 81 / 8%) -14.94%, rgb(42 59 62 / 48%) 88.71%)`}
            scrollTop={true}
            lang={node_locale}
          />
        </div>
      </div>
    </>
  );
}
