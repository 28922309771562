import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import clsx from "clsx";
import { useSnackbar } from "notistack";
import React, { useContext, useEffect, useState } from "react";

import { SlideTransition } from "../../../../components/SlideTransition/SlideTransition";
import { SlipTransition } from "../../../../components/SlipTransition/SlipTransition";
import { IP_BLOCKED } from "../../../../helpers/constants";
import { getClientIp } from "../../../../helpers/getClientIp";
import {
  InspirationContainerContext,
  InspirationPageContext,
} from "../../../../helpers/pagesContext";
import * as styles from "./Hero.module.css";

export const Hero = ({
  search,
  handleChange,
  handleClean,
  analysis,
  setValueTab,
  setIsArrowShow,
}) => {
  const { addBlock, quality, shortcut } = useContext(
    InspirationContainerContext
  );
  const { filter } = useContext(InspirationPageContext);
  const { enqueueSnackbar } = useSnackbar();
  const [ip, setIp] = useState(null);

  const handleClick = () => {
    setIsArrowShow(true);
    filter("ORDERED_LIKE");
    setValueTab(4);
    analysis();
  };

  useEffect(() => {
    if (!ip)
      getClientIp().then(function (result) {
        setIp(result);
        if (result === IP_BLOCKED)
          enqueueSnackbar(addBlock, {
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
            variant: "info",
          });
      });
  }, []);

  return (
    <section className={styles.root}>
      <div className={styles.container}>
        <SlipTransition
          lines={[quality]}
          color="#fff"
          important={true}
          options={{ delay: 0.4, animateY: -150 }}
        />
        <div className={styles.formControl}>
          <SlideTransition delay={0.4}>
            <FormControl variant="standard">
              <InputLabel
                className={styles.label}
                htmlFor="standard-adornment-search"
              >
                {shortcut}
              </InputLabel>
              <Input
                className={clsx(
                  search.searching ? styles.inputActive : styles.input
                )}
                id="standard-adornment-search"
                type="text"
                value={search.value}
                onChange={handleChange()}
                onKeyPress={(e) => {
                  if (e.key === "Enter") handleClick();
                }}
                endAdornment={
                  <InputAdornment position="end">
                    {!!search.value && (
                      <>
                        <IconButton
                          aria-label="toggle close"
                          onClick={handleClean}
                        >
                          <CloseIcon
                            sx={{
                              color: "#fff",
                              fontSize: 15,
                              backgroundColor: "rgba(255, 255, 255, 0.33)",
                              borderRadius: "50%",
                              padding: "2px",
                              marginRight: "4px",
                            }}
                          />
                        </IconButton>
                        <Divider
                          orientation="vertical"
                          sx={{ backgroundColor: "#ffffff33", height: 17 }}
                        />
                      </>
                    )}
                    <IconButton
                      aria-label="toggle search"
                      size="medium"
                      onClick={handleClick}
                    >
                      <SearchIcon sx={{ color: "#fff" }} />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </SlideTransition>
        </div>
      </div>
    </section>
  );
};
