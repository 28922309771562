import CircularProgress from "@mui/material/CircularProgress";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import * as styles from "./InspirationSearchDesktop.module.css";

const INFINITE_SCROLL_LENGHT = 4;

export const InspirationSearchDesktop = ({ edges, item, setIsArrowShow }) => {
  const [stateInf, setStateInf] = useState({
    items: edges.slice(0, INFINITE_SCROLL_LENGHT),
    hasMore: true,
  });

  const fetchMoreData = () => {
    if (stateInf.items.length >= edges.length) setIsArrowShow(false);

    setTimeout(() => {
      setStateInf({
        ...stateInf,
        items: [
          ...stateInf.items,
          ...edges.slice(
            stateInf.items.length,
            stateInf.items.length + INFINITE_SCROLL_LENGHT
          ),
        ],
      });
    }, 1000);
  };

  useEffect(() => {
    setStateInf({
      ...stateInf,
      items: edges.slice(0, INFINITE_SCROLL_LENGHT),
      haseMore: true,
    });
  }, [edges]);

  return (
    <div className={styles.infiniteScroll}>
      <InfiniteScroll
        dataLength={stateInf.items.length}
        next={fetchMoreData}
        hasMore={stateInf.hasMore}
        loader={
          stateInf.items.length < edges.length && (
            <div className={styles.buttonProgress}>
              <CircularProgress size={30} />
            </div>
          )
        }
      >
        <div className={styles.wrap}>
          <div>
            {stateInf.items.map(({ node }, idx) => {
              if (idx % 2 === 0) {
                return <div key={idx}>{item({ node, idx })}</div>;
              }
            })}
          </div>
          <div>
            {stateInf.items.map(({ node }, idx) => {
              if (!(idx % 2 === 0)) {
                return <div key={idx}>{item({ node, idx })}</div>;
              }
            })}
          </div>
        </div>
      </InfiniteScroll>
    </div>
  );
};
