import "moment/locale/fr";

import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import { InspirationContainerContext } from "../../../../../helpers/pagesContext";
import * as styles from "./InspirationDesktop.module.css";

const INFINITE_SCROLL_LENGHT = 4;

export const InspirationDesktop = ({
  edges,
  item,
  currentMenu,
  setIsArrowShow,
}) => {
  const { node_locale } = useContext(InspirationContainerContext);
  if (node_locale === "fr-FR") moment.locale("fr");
  else moment.locale("en");

  let mounthAlreadyPresent = [];

  const [stateInf, setStateInf] = useState({
    items: edges.slice(0, INFINITE_SCROLL_LENGHT),
    hasMore: true,
  });

  const fetchMoreData = () => {
    if (stateInf.items.length >= edges.length) setIsArrowShow(false);

    setTimeout(() => {
      setStateInf({
        ...stateInf,
        items: [
          ...stateInf.items,
          ...edges.slice(
            stateInf.items.length,
            stateInf.items.length + INFINITE_SCROLL_LENGHT
          ),
        ],
      });
    }, 1000);
  };

  useEffect(() => {
    mounthAlreadyPresent = [];
    setStateInf({
      ...stateInf,
      items: edges.slice(0, INFINITE_SCROLL_LENGHT),
      haseMore: true,
    });
  }, [edges]);

  return (
    <div className={styles.infiniteScroll}>
      <InfiniteScroll
        dataLength={stateInf.items.length}
        next={fetchMoreData}
        hasMore={stateInf.hasMore}
        loader={
          stateInf.items.length < edges.length && (
            <div className={styles.buttonProgress}>
              <CircularProgress size={30} />
            </div>
          )
        }
      >
        <div className={styles.wrap}>
          <div>
            {stateInf.items.map(({ node }, idx) => {
              if (idx % 2 === 0) {
                let div;

                if (
                  currentMenu !== "COUPS DE CŒUR" &&
                  currentMenu !== "MES INSPIRATIONS"
                ) {
                  const dateFormat = moment(node.creation).format("MMM");

                  div = !mounthAlreadyPresent.includes(dateFormat) && (
                    <div className={styles.date}>{dateFormat}</div>
                  );

                  mounthAlreadyPresent = [...mounthAlreadyPresent, dateFormat];
                }

                return (
                  <div key={idx}>
                    {div}
                    {item({ node, idx })}
                  </div>
                );
              }
            })}
          </div>
          <div>
            {stateInf.items.map(({ node }, idx) => {
              if (!(idx % 2 === 0)) {
                return <div key={idx}>{item({ node, idx })}</div>;
              }
            })}
          </div>
        </div>
      </InfiniteScroll>
    </div>
  );
};
