import { Paper } from "@mui/material";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import { styled } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Tooltip from "@mui/material/Tooltip";
import clsx from "clsx";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { IS_LIKED, ORDERED_LIKE } from "../../../../helpers/constants";
import {
  InspirationContainerContext,
  InspirationPageContext,
} from "../../../../helpers/pagesContext";
import * as styles from "./SearchMenu.module.css";

const StyledTooltip = styled(({ className, title = "default", ...props }) => (
  <Tooltip title={title} {...props} classes={{ popper: className }} />
))`
  & .MuiTooltip-tooltip {
    background: #fff;
    font-family: "Futura";
    color: #2f3f45;
    padding: 10px 15px;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 1px;
    box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.04);
  }
  .MuiTooltip-arrow {
    color: #fff;
  }
`;

const subMenuPosition = 3;

export default function SearchMenu({
  menu,
  valueTab,
  setValueTab,
  cleanSearch,
  searching,
  setIsArrowShow,
}) {
  const { search, cancel, menuInspiration } = useContext(
    InspirationContainerContext,
  );
  const { filter } = useContext(InspirationPageContext);
  const ref = useRef(null);
  const [open, setOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const checkbox = [
    { label: menuInspiration[0], value: 1 },
    { label: menuInspiration[1], value: 2 },
    { label: menuInspiration[2], value: 3 },
    { label: menuInspiration[3], value: 4 },
    { label: menuInspiration[4], value: 5 },
  ];

  const { handleSubmit, control, reset, setValue } = useForm();

  const handleChangeTab = (_, newValueTab) => {
    cleanSearch();
    setIsArrowShow(true);
    setValueTab(newValueTab);

    switch (newValueTab) {
      case 0:
        cancelSearch();
        break;
      case 1:
        filter(ORDERED_LIKE);
        break;
      case 2:
        filter(IS_LIKED);
        break;
      case 3:
        setOpen(false);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    setValue("inspirations", selectedItems);
  }, [selectedItems]);

  useEffect(() => {
    if (searching) cancelSearch();
  }, [searching]);

  const handleSelect = (value) => {
    const isPresent = selectedItems.indexOf(value);
    if (isPresent !== -1) {
      const remaining = selectedItems.filter((item) => item !== value);
      setSelectedItems(remaining);
    } else {
      setSelectedItems((prevItems) => [...prevItems, value]);
    }
  };

  const cancelSearch = () => {
    reset();
    setSelectedItems([]);
    setOpen(false);
    filter();
  };

  const onSubmit = (value) => {
    setOpen(false);
    filter(value);
  };

  return (
    <div className={styles.tabs}>
      <Tabs
        orientation="horizontal"
        value={valueTab}
        onChange={handleChangeTab}
        TabIndicatorProps={{
          style: { background: "#cea463" },
        }}
        className={clsx(styles.tabs, styles.tabsResp)}
      >
        {menu.map((elem, index) => (
          <StyledTooltip
            key={index}
            title={elem.title}
            placement="top"
            arrow
            enterDelay={800}
            enterNextDelay={600}
          >
            <Tab
              className={styles.tab}
              onClick={() => index === subMenuPosition && setOpen(true)}
              label={
                <>
                  <h2
                    className={clsx(
                      styles.tab,
                      valueTab === index && styles.activeTab,
                    )}
                  >
                    {elem.name}
                  </h2>
                  {elem.name === "FILTRER" && (
                    <>
                      {!!selectedItems.length && (
                        <div className={styles.filterBubble}>
                          {selectedItems.length}
                        </div>
                      )}
                      <div
                        className={styles.chevron}
                        onClick={() => {
                          if (!open) setValueTab(subMenuPosition);
                          setOpen(!open);
                        }}
                      ></div>
                    </>
                  )}
                </>
              }
            />
          </StyledTooltip>
        ))}
      </Tabs>
      {open && (
        <Paper elevation={24} className={styles.paperBox} ref={ref}>
          <div className={styles.dialBox} />
          <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
            <FormGroup className={styles.formGroup}>
              {checkbox.map((elem, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Controller
                      name="inspirations"
                      control={control}
                      defaultValue={false}
                      render={(props) => (
                        <Checkbox
                          {...props}
                          checked={selectedItems.includes(elem.value)}
                          onChange={() => handleSelect(elem.value)}
                          style={{
                            color: "#CEA463",
                            transform: "scale(0.7) translate(10px,-1px)",
                          }}
                        />
                      )}
                    />
                  }
                  label={
                    <span className={styles.checkboxLabel}>{elem.label}</span>
                  }
                />
              ))}
            </FormGroup>
            <div>
              <Button
                type="submit"
                className={styles.button}
                variant="contained"
                sx={{
                  borderRadius: "8px 0px 8px 8px",
                  width: "80%",
                  marginLeft: "10%",
                  marginTop: "30px",
                  padding: "10px 0",
                  backgroundColor: "#cea463",
                }}
              >
                {search}
              </Button>
              <Button
                onClick={() => cancelSearch()}
                className={styles.buttonCancel}
                size="small"
                sx={{
                  fontSize: "13px",
                  fontFamily: "Futura",
                  color: "#7d7d7d",
                  textDecoration: "underline",
                  textTransform: "capitalize",
                  left: "50%",
                  transform: "translateX(-50%)",
                  marginTop: "20px",
                }}
              >
                {cancel}
              </Button>
            </div>
          </form>
        </Paper>
      )}
    </div>
  );
}
